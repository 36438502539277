<template>
    <div class="animated fadeIn">
        <b-card :title="$t('message.monthly')">
            <Tabs value="overview" @on-click="changeTab">
                <!--             tab overview          -->
                <TabPane :label="$t('message.dataOverview')" name="overview">
                    <b-form @submit.prevent="searchFn">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.year") }} :
                                <br />
                                <b-form-select v-model="timeSel" :options="language == 'th' ? timeOptTH : timeOptEN"
                                    v-on:change="selectTime" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.machineIdFarm") }} :
                                <br />
                                <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                                    :selected-items="selectedMachine" :placeholder="$t('message.all')"
                                    @select="onSelectMachine">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.selectDateTime") }} :
                                <br />
                                <date-time-picker v-model="searchData.datetime"></date-time-picker>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-3">
                                <b-btn class="col-sm-12 col-md-5 my-1" variant="primary"
                                    v-on:click="searchFn({ excel: false })">
                                    <i class="fa fa-search"></i>
                                    &nbsp;{{ $t("message.search") }}
                                </b-btn>
                                &nbsp;
                                <b-btn class="col-sm-12 col-md-5 my-2 " v-on:click="searchFn({ excel: true })"
                                    variant="success" v-if="$isRole('owner', role_id)">
                                    <i class="icon-docs"></i> &nbsp;{{ $t("message.export") }}
                                </b-btn>
                            </div>
                        </div>
                        <br />
                    </b-form>

                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t("message.amountCustomer") }} : {{
                            totalRows | formatNumber
                        }}</div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.totalRevenue') }} : {{
                            summary.all | formatNumber
                        }}</div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.averageIncome') }} : {{
                            Number(summary.avg_monthUsed).toLocaleString(undefined, { maximumFractionDigits: 2 })
                        }}
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.AverageLastActiveDay') }}
                            : {{
                                Number(summary.avg_lastDayOfUse).toLocaleString(undefined, {
                                    maximumFractionDigits: 2
                                })
                            }} </div>

                    </div>
                    <br />

                    <div v-if="totalRows > 0">

                        <div class="row">
                            <div :class="yearMonthIntersection.length > 6 ? 'col-sm-6 col-md-12 col-lg-12  col-xl-12 my-1  table-responsive' : 'col-sm-6 col-md-12 col-lg-12  col-xl-6 my-1 '"
                                id="chart">
                                <apexchart type="line" height="350" :options="chartOptions" :series="series">
                                </apexchart>
                            </div>

                            <div :class="yearMonthIntersection.length > 6 ? 'col-sm-6 col-md-12 col-lg-12  col-xl-12 my-1' : 'col-sm-6 col-md-12 col-lg-12  col-xl-6 my-1'"
                                :style="yearMonthIntersection.length > 6 ? 'padding-left: 12%; padding-right: 12%' : ''">
                                <table class=" table-grid table-responsive ">
                                    <thead style="text-align-last: center">
                                        <th style="min-width: 170px"> </th>
                                        <template v-for="yearMonth in yearMonthIntersection">
                                            <th style="min-width: 80px"> {{ getMonthName(yearMonth) }}</th>
                                        </template>
                                        <th style="min-width: 86px">{{ $t("message.tote") }}</th>
                                    </thead>
                                    <tbody style="text-align: right">
                                        <tr>
                                            <td>Revenue</td>
                                            <template v-for="(row, index) in overviewData">
                                                <td>
                                                    {{ Number(row.total).toLocaleString() }}
                                                </td>
                                            </template>
                                            <td>{{ Number(summary.summary_Revenue).toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <td>New Customer</td>
                                            <template v-for="(row, index) in overviewData">
                                                <td>
                                                    {{ Number(row.customer_all).toLocaleString() }} คน
                                                </td>
                                            </template>
                                            <td>{{ Number(summary.summary_customerAll).toLocaleString() }} คน</td>
                                        </tr>
                                        <tr>
                                            <td>Active</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td>
                                                    {{ Number(row.customerActive).toLocaleString() }} คน
                                                </td>
                                            </template>
                                            <td>{{ Number(summary.summary_active).toLocaleString() }} คน</td>
                                        </tr>
                                        <tr>
                                            <td>Inactive</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td>
                                                    {{ Number(row.customerInactive).toLocaleString() }} คน
                                                </td>
                                            </template>
                                            <td>{{ Number(summary.summary_inactive).toLocaleString() }} คน</td>
                                        </tr>
                                        <tr>
                                            <td>1stTimeUse</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td>
                                                    {{ Number(row.customer1stTimeUse).toLocaleString() }} คน
                                                </td>
                                            </template>
                                            <td>{{ Number(summary.summary_1stTimeUse).toLocaleString() }} คน</td>
                                        </tr>
                                        <tr>
                                            <td>NeedCheck</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td>
                                                    {{ Number(row.customerNeedCheck).toLocaleString() }} คน
                                                </td>
                                            </template>
                                            <td>{{ Number(summary.summary_needCheck).toLocaleString() }} คน</td>
                                        </tr>
                                        <tr>
                                            <td>Lost after 1st use in %</td>
                                            <template v-for="(row, index) in overviewData">
                                                <td>
                                                    {{
                                                        Number(row.summary_1stUseIn).toLocaleString(undefined, {
                                                            maximumFractionDigits: 2
                                                        })
                                                    }} %
                                                </td>
                                            </template>
                                            <td>
                                                {{ Number(summary.summary_1stUseIn).toLocaleString() }} %
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- <div class="col-sm-6 col-md-12 col-lg-5 my-1" id="chart">
                                <h4> กราฟรายได้ </h4>
                                <apexchart type="pie" width="100%" :options="chartOptions_pie" :series="series_pie">
                                </apexchart>
                            </div> -->
                        </div>
                        <!-- <div class="row my-1">
                            <div id="chart" class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <div id="chart">
                                    <apexchart type="bar" height="350" :options="chartOptions" :series="series">
                                    </apexchart>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <h4> ลูกค้าที่ใช้งานอยู่ </h4>
                                <table class="table-grid">
                                    <thead style="text-align-last: center">
                                        <th> </th>
                                        <template v-for="yearMonth in yearMonthIntersection">
                                            <th> {{ getMonthName(yearMonth) }}</th>
                                        </template>
                                        <th>{{ $t("message.tote") }}</th>
                                    </thead>
                                    <tbody style="text-align: right">
                                        <template v-for="(row, index) in overviewData">
                                            <tr>
                                                <td style="min-width: 100px;">ใช้งานครั้งสุดท้าย &nbsp;
                                                    {{ getMonthName(row.yearMonth) }} </td>
                                                <template v-for="data in row.detail_active">
                                                    <td> {{ data.customer }} คน</td>
                                                </template>
                                                <td> {{ row.summaryDetail_active }} คน</td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td>{{ $t("message.tote") }}</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td> {{ row.customerActive }} คน</td>
                                            </template>
                                            <td><span class="subtotal">{{ summaryData_yearMonth.reduce((a, c) => a +
                                                    parseInt(c.customerActive), 0)
                                            }}</span> คน</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row my-1">
                            <div id="chart" class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <div id="chart">
                                    <apexchart type="bar" height="350" :options="chartOptions" :series="series">
                                    </apexchart>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <h4> ลูกค้าที่ไม่ได้ใช้งาน </h4>
                                <table class="table-grid">
                                    <thead style="text-align-last: center">
                                        <th> </th>
                                        <template v-for="yearMonth in yearMonthIntersection">
                                            <th> {{ getMonthName(yearMonth) }}</th>
                                        </template>
                                        <th>{{ $t("message.tote") }}</th>
                                    </thead>
                                    <tbody style="text-align: right">
                                        <template v-for="(row, index) in overviewData">
                                            <tr>
                                                <td style="min-width: 100px;">ใช้งานครั้งสุดท้าย &nbsp;
                                                    {{ getMonthName(row.yearMonth) }} </td>
                                                <template v-for="data in row.detail_inactive">
                                                    <td> {{ data.customer }} คน</td>
                                                </template>
                                                <td> {{ row.summaryDetail_inactive }} คน</td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td>{{ $t("message.tote") }}</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td> {{ row.customerInactive }} คน</td>
                                            </template>
                                            <td><span class="subtotal">{{ summaryData_yearMonth.reduce((a, c) => a +
                                                    parseInt(c.customerInactive), 0)
                                            }}</span> คน</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row my-1">
                            <div id="chart" class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <div id="chart">
                                    <apexchart type="bar" height="350" :options="chartOptions" :series="series">
                                    </apexchart>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <h4> ลูกค้าที่น่าติดตาม </h4>
                                <table class="table-grid">
                                    <thead style="text-align-last: center">
                                        <th> </th>
                                        <template v-for="yearMonth in yearMonthIntersection">
                                            <th> {{ getMonthName(yearMonth) }}</th>
                                        </template>
                                        <th>{{ $t("message.tote") }}</th>
                                    </thead>
                                    <tbody style="text-align: right">
                                        <template v-for="(row, index) in overviewData">
                                            <tr>
                                                <td style="min-width: 100px;">ใช้งานครั้งสุดท้าย &nbsp;
                                                    {{ getMonthName(row.yearMonth) }} </td>
                                                <template v-for="data in row.detail_needCheck">
                                                    <td> {{ data.customer }} คน</td>
                                                </template>
                                                <td> {{ row.summaryDetail_needCheck }} คน</td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td>{{ $t("message.tote") }}</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td> {{ row.customerNeedCheck }} คน</td>
                                            </template>
                                            <td><span class="subtotal">{{ summaryData_yearMonth.reduce((a, c) => a +
                                                    parseInt(c.customerNeedCheck), 0)
                                            }}</span> คน</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row my-1">
                            <div id="chart" class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <div id="chart">
                                    <apexchart type="bar" height="350" :options="chartOptions" :series="series">
                                    </apexchart>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 my-1">
                                <h4> ลูกค้าที่มาใช้งานครั้งเดียว </h4>
                                <table class="table-grid">
                                    <thead style="text-align-last: center">
                                        <th> </th>
                                        <template v-for="yearMonth in yearMonthIntersection">
                                            <th> {{ getMonthName(yearMonth) }}</th>
                                        </template>
                                        <th>{{ $t("message.tote") }}</th>
                                    </thead>
                                    <tbody style="text-align: right">
                                        <template v-for="(row, index) in overviewData">
                                            <tr>
                                                <td style="min-width: 100px;">ใช้งานครั้งสุดท้าย &nbsp;
                                                    {{ getMonthName(row.yearMonth) }} </td>
                                                <template v-for="data in row.detail_1stTimeUse">
                                                    <td> {{ data.customer }} คน</td>
                                                </template>
                                                <td> {{ row.summaryDetail_1stTimeUse }} คน</td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td>{{ $t("message.tote") }}</td>
                                            <template v-for="(row, index) in summaryData_yearMonth">
                                                <td> {{ row.customer1stTimeUse }} คน</td>
                                            </template>
                                            <td><span class="subtotal">{{ summaryData_yearMonth.reduce((a, c) => a +
                                                    parseInt(c.customer1stTimeUse), 0)
                                            }}</span> คน</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->

                    </div>

                </TabPane>

                <!--             tab individual        -->
                <TabPane :label="$t('message.dataIndividual')" name="individual">
                    <b-form @submit.prevent="searchFn">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.year") }} :
                                <br />
                                <b-form-select v-model="timeSel" :options="timeOpt" v-on:change="selectTime" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.machineIdFarm") }} :
                                <br />
                                <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                                    :selected-items="selectedMachine" :placeholder="$t('message.all')"
                                    @select="onSelectMachine">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                                {{ $t("message.refNumber") }} :
                                <br />
                                <b-form-input v-model="searchData.refNumber" type="number" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                                {{ $t("message.selectDateTime") }} :
                                <br />
                                <date-time-picker v-model="searchData.datetime"></date-time-picker>
                            </div>
                        </div>

                        <div class="row">
                            <!-- <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.yearMonth(ChristianEra)") }} :
                                <br />
                                <b-form-input v-model="searchData.yearMonth" placeholder="" />
                            </div> -->

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.CustomerStatus") }} :
                                <br />
                                <multi-list-select :list="$t('message.arrays.caseCustomerStatus')" option-value="value"
                                    option-text="text" :selected-items="selectedCustomerStatus"
                                    :placeholder="$t('message.all')" @select="onSelectCustomerStatus">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.statusUse") }} :
                                <br />
                                <multi-list-select :list="$t('message.arrays.caseStatusUse')" option-value="value"
                                    option-text="text" :selected-items="selectedStatusUse"
                                    :placeholder="$t('message.all')" @select="onSelectStatusUse">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                                {{ $t("message.desiredRank") }} :
                                <br />
                                <b-form-input v-model="searchData.reqRank" :placeholder="$t('message.all')" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-3">
                                <b-btn class="col-sm-12 col-md-5 my-1" variant="primary"
                                    v-on:click="searchFn({ excel: false })">
                                    <i class="fa fa-search"></i>
                                    &nbsp;{{ $t("message.search") }}
                                </b-btn>
                                &nbsp;
                                <b-btn class="col-sm-12 col-md-5 my-2 " v-on:click="searchFn({ excel: true })"
                                    variant="success" v-if="$isRole('owner', role_id)">
                                    <i class="icon-docs"></i> &nbsp;{{ $t("message.export") }}
                                </b-btn>
                            </div>
                        </div>
                        <br />
                    </b-form>

                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t("message.amountCustomer") }} : {{
                            totalRows | formatNumber
                        }}</div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.totalRevenue') }} : {{
                            summary.all | formatNumber
                        }}</div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.averageIncome') }} :
                            {{ summary.avg_monthUsed | formatNumber }}
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.AverageLastActiveDay') }}
                            : {{ summary.avg_lastDayOfUse | formatNumber }} </div>

                    </div>
                    <br />

                    <!-- {{rowData.rows}} -->

                    <div class="table-responsive">
                        <Table stripe row-key="transactionId" size="small" :columns="column" :data="rowData.rows" />
                    </div>
                    <br />
                    <Page :total="totalRows" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />

                </TabPane>

                <!--             tab newCustoemr        -->
                <TabPane label="ลูกค้ารายใหม่" name="newcustomer">
                    <b-form @submit.prevent="searchFn">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.year") }} :
                                <br />
                                <b-form-select v-model="timeSel" :options="timeOpt" v-on:change="selectTime" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.machineIdFarm") }} :
                                <br />
                                <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                                    :selected-items="selectedMachine" :placeholder="$t('message.all')"
                                    @select="onSelectMachine">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                                {{ $t("message.refNumber") }} :
                                <br />
                                <b-form-input v-model="searchData.refNumber" type="number" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                                {{ $t("message.selectDateTime") }} :
                                <br />
                                <date-picker-custom v-model="searchData.datetime"></date-picker-custom>
                            </div>
                        </div>

                        <div class="row">
                            <!-- <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                        {{ $t("message.yearMonth(ChristianEra)") }} :
                                        <br />
                                        <b-form-input v-model="searchData.yearMonth" placeholder="" />
                                    </div> -->

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.CustomerStatus") }} :
                                <br />
                                <multi-list-select :list="$t('message.arrays.caseCustomerStatus')" option-value="value"
                                    option-text="text" :selected-items="selectedCustomerStatus"
                                    :placeholder="$t('message.all')" @select="onSelectCustomerStatus">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                                {{ $t("message.statusUse") }} :
                                <br />
                                <multi-list-select :list="$t('message.arrays.caseStatusUse')" option-value="value"
                                    option-text="text" :selected-items="selectedStatusUse"
                                    :placeholder="$t('message.all')" @select="onSelectStatusUse">
                                </multi-list-select>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                                {{ $t("message.desiredRank") }} :
                                <br />
                                <b-form-input v-model="searchData.reqRank" :placeholder="$t('message.all')" />
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-3">
                                <b-btn class="col-sm-12 col-md-5 my-1" variant="primary"
                                    v-on:click="searchFn({ excel: false })">
                                    <i class="fa fa-search"></i>
                                    &nbsp;{{ $t("message.search") }}
                                </b-btn>
                                &nbsp;
                                <b-btn class="col-sm-12 col-md-5 my-2 " v-on:click="searchFn({ excel: true })"
                                    variant="success" v-if="$isRole('owner', role_id)">
                                    <i class="icon-docs"></i> &nbsp;{{ $t("message.export") }}
                                </b-btn>
                            </div>
                        </div>
                        <br />
                    </b-form>

                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t("message.amountCustomer") }} : {{
                            totalRows | formatNumber
                        }}</div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.totalRevenue') }} : {{
                            summary.all | formatNumber
                        }}</div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.averageIncome') }} : {{
                            Number(summary.avg_monthUsed).toLocaleString(undefined, { maximumFractionDigits: 2 })
                        }}
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.AverageLastActiveDay') }}
                            : {{
                                Number(summary.avg_lastDayOfUse).toLocaleString(undefined, {
                                    maximumFractionDigits: 2
                                })
                            }} </div>

                    </div>
                    <br />

                    <div class="table-responsive">
                        <Table stripe row-key="transactionId" size="small" :columns="column" :data="rowData.rows" />
                    </div>
                    <br />
                    <Page :total="totalRows" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />

                </TabPane>
            </Tabs>
        </b-card>
    </div>
</template>
<script>

import Vue from 'vue'
import webServices from '../../script'
import DatePickerCustom from '../../components/DateTimePicker/Custom.vue'
// import DateTimePicker from "../../components/DateTimePicker/DatePicker.vue";
// import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
import DateTimePicker from "../../components/DateTimePicker/MonthCustom";
import FileSaver from 'file-saver'
import { MultiSelect, MultiListSelect } from '../../custom_modules/search-select'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
    return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

import Multiselect from 'vue-multiselect'

export default {
    name: "orders",
    components: {
        DatePickerCustom,
        DateTimePicker,
        MultiSelect,
        MultiListSelect,
        VueJsonPretty,
        Multiselect,
    },
    data() {
        return {
            api: {},
            timeSel: 0,
            timeOptTH: [
                { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
                { value: 1, text: '<2022' }
            ],
            timeOptEN: [
                { value: 0, text: '2022 To This Year' },
                { value: 1, text: '<2022' }
            ],
            role_id: window.localStorage.getItem("roleID"),
            userBusiness: window.localStorage.getItem("business"),
            language: window.localStorage.getItem("language"),
            fileName: '',
            rowData: {},
            overviewData: {},
            totalRows: 0,
            totalSubRows: 0,
            currentPage: 1,
            itemperPage: 50,
            summary: {
                avg_lastDayOfUse: 0,
                avg_monthUsed: 0,
                summary_customerAll: 0,
                summary_active: 0,
                summary_inactive: 0,
                summary_1stTimeUse: 0,
                summary_needCheck: 0,
                summary_Revenue: 0,
                summary_1stUseIn: 0,
            },
            lastUse_byYeatMonth: [],
            yearMonthIntersection: [],
            summaryData_yearMonth: [],
            selectedProduct: [],
            selectedMachine: [],
            selectedStatusUse: [],
            selectedCustomerStatus: [],
            max: 0,
            machineData: [],
            selectedId: {},
            searchData: {
                caseSelect: 'overview',
                rank: 50,
                reqRank: '',
                machineId: '',
                datetime: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            },
            value: [],
            column: [
                {
                    title: this.$t('message.No'),
                    key: 'index',
                    minWidth: 80,
                    align: 'center'
                },
                {
                    title: this.$t('message.machineIdFarm'),
                    key: 'machineId',
                    minWidth: 100,
                    align: 'center'
                },
                {
                    title: this.$t('message.refNumber'),
                    key: 'refNumber',
                    minWidth: 140,
                    align: 'right'
                },
                // {
                //     title: 'createdAt',
                //     minWidth: 160,
                //     align: 'center',
                //     render: (h, params) => {
                //         return h('span', moment(params.row.CustomerCredits.createdAt).format('YYYY-MM-DD HH:mm:ss'))
                //     }
                // },
                {
                    title: this.$t('message.datetime_createdAt'),
                    minWidth: 120,
                    align: 'center',
                    ellipsis: true,
                    // tooltip: true,
                    render: (h, params) => {
                        // return h('span', params.row.machine.name)
                        return h(
                            'Poptip',
                            {
                                props: {
                                    trigger: 'hover',
                                    content: params.row.CustomerCredits.yearMonth_CustomerCredits,
                                    // content:  moment(params.row.CustomerCredits.createdAt).format('YYYY-MM-DD HH:mm:ss')
                                }
                            },
                            [h('span', moment(params.row.CustomerCredits.createdAt).format('YYYY-MM-DD HH:mm'))]
                            // [h('span',  params.row.CustomerCredits.yearMonth )]
                        )
                    }
                },

                // {
                //     title: 'yearMonth',
                //     // key: 'UseStatus',
                //     minWidth: 100,
                //     align: 'right',
                //     render: (h, params) => {
                //         return h('span', params.row.CustomerCredits.yearMonth)
                //     }
                // },
                {
                    title: this.$t('message.datetime_startAt'),
                    minWidth: 160,
                    align: 'center',
                    render: (h, params) => {
                        // return h('span', moment(params.row.startDate).format('YYYY-MM-DD HH:mm'))
                        return h(
                            'Poptip',
                            {
                                props: {
                                    trigger: 'hover',
                                    content: params.row.yearMonth_startDate,
                                }
                            },
                            [h('span', moment(params.row.startDate).format('YYYY-MM-DD HH:mm'))]
                        )
                    }
                },
                {
                    title: this.$t('message.datetime_lastAt'),
                    minWidth: 160,
                    align: 'center',
                    render: (h, params) => {
                        // return h('span', moment(params.row.lastDate).format('YYYY-MM-DD HH:mm'))
                        return h(
                            'Poptip',
                            {
                                props: {
                                    trigger: 'hover',
                                    content: params.row.yearMonth_lastDate,
                                }
                            },
                            [h('span', moment(params.row.lastDate).format('YYYY-MM-DD HH:mm'))]
                        )
                    }
                },
                {
                    title: this.$t('message.amountItems'),
                    key: 'trans',
                    minWidth: 116,
                    align: 'right',
                    render: (h, params) => {
                        return h('span', Vue.filter('formatNumber')(params.row.trans))
                    }
                },
                {
                    title: this.$t('message.insert'),
                    key: 'total',
                    minWidth: 100,
                    align: 'right',
                    render: (h, params) => {
                        return h('span', Vue.filter('formatNumber')(params.row.total))
                    }
                },
                {
                    title: this.$t('message.amountDays'),
                    key: 'amountOfDays',
                    minWidth: 120,
                    align: 'right',
                },
                {
                    title: this.$t('message.amountMonths'),
                    key: 'amountOfMonth',
                    minWidth: 132,
                    align: 'right',
                },
                // {
                //     title: 'จำนวนเดือน',
                //     key: 'monthUsed',
                //     minWidth: 120,
                //     align: 'right',
                // },
                {
                    title: this.$t('message.lastUsed'),
                    key: 'lastDayOfUse',
                    minWidth: 100,
                    align: 'right',
                },

                {
                    title: this.$t('message.average'),
                    key: 'avg',
                    minWidth: 100,
                    align: 'right',
                    render: (h, params) => {
                        if (params.row.avg_monthUsed != null) {
                            return h('span', params.row.avg_monthUsed.toFixed(2))
                            // return h('span', params.row.avg_monthUsed)
                        } else {
                            return h('span', '0.00')
                        }
                    }
                },
                // {
                //     title: 'ประเภทลูกค้า',
                //     minWidth: 130,
                //     align: 'center',
                //     render: this.showCustomerStatus
                // },
                {
                    title: this.$t('message.statusUse'),
                    // key: 'UseStatus',
                    minWidth: 110,
                    align: 'center',
                    render: this.showUseStatus
                },
                {
                    title: this.$t('message.detail'),
                    // slot: 'action',
                    width: 100,
                    align: 'center',
                    render: this.renderDetail
                }
            ],


            series_pie: [],
            chartOptions_pie: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                // labels: [2,4,6],
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            series: [
                {
                    name: 'New Customer',
                    type: "column",
                    data: []
                },
                {
                    name: 'Active',
                    type: "column",
                    data: []
                },
                {
                    name: 'Inactive',
                    type: "column",
                    data: []
                },
                {
                    name: '1stTimeUse',
                    type: "column",
                    data: []
                },
                {
                    name: 'NeedCheck',
                    type: "column",
                    data: []
                },
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                    type: "category",
                    tickAmount: undefined,
                    tickPlacement: "between",
                    min: undefined,
                    max: undefined,
                    range: undefined,
                    floating: false,
                    decimalsInFloat: undefined,
                    overwriteCategories: undefined,
                    position: "bottom",
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                            colors: [],
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            cssClass: "apexcharts-xaxis-label",
                        },
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return Number(value).toLocaleString()
                        }
                    },
                },
                fill: {
                    opacity: 1
                },
            },


        };
    },
    async mounted() {
        await this.setParams(this.$route.query);
        await this.getMachine();
        if (
            !this.$isRole("admin", this.role_id) &&
            !this.$isRole("callcenter", this.role_id)
        ) {
            await this.getMonthly();
        }
    },

    methods: {
        getMonthName(yearname) {
            let monthName = ''
            let year = ''
            year = yearname.toString().substring(0, 4)

            if (window.localStorage.getItem("language") == 'th') {
                if (yearname.toString().substring(4, 6) == '01') { monthName = 'ม.ค' }
                if (yearname.toString().substring(4, 6) == '02') { monthName = 'ก.พ.' }
                if (yearname.toString().substring(4, 6) == '03') { monthName = 'มี.ค.' }
                if (yearname.toString().substring(4, 6) == '04') { monthName = 'เม.ย.' }
                if (yearname.toString().substring(4, 6) == '05') { monthName = 'พ.ค.' }
                if (yearname.toString().substring(4, 6) == '06') { monthName = 'มิ.ย.' }
                if (yearname.toString().substring(4, 6) == '07') { monthName = 'ก.ค.' }
                if (yearname.toString().substring(4, 6) == '08') { monthName = 'ส.ค.' }
                if (yearname.toString().substring(4, 6) == '09') { monthName = 'ก.ย.' }
                if (yearname.toString().substring(4, 6) == '10') { monthName = 'ต.ค.' }
                if (yearname.toString().substring(4, 6) == '11') { monthName = 'พ.ย.' }
                if (yearname.toString().substring(4, 6) == '12') { monthName = 'ธ.ค.' }
            } else {
                if (yearname.toString().substring(4, 6) == '01') { monthName = 'Jan.' }
                if (yearname.toString().substring(4, 6) == '02') { monthName = 'Feb.' }
                if (yearname.toString().substring(4, 6) == '03') { monthName = 'Mar.' }
                if (yearname.toString().substring(4, 6) == '04') { monthName = 'Apr.' }
                if (yearname.toString().substring(4, 6) == '05') { monthName = 'May.' }
                if (yearname.toString().substring(4, 6) == '06') { monthName = 'Jun.' }
                if (yearname.toString().substring(4, 6) == '07') { monthName = 'Jul.' }
                if (yearname.toString().substring(4, 6) == '08') { monthName = 'Aug.' }
                if (yearname.toString().substring(4, 6) == '09') { monthName = 'Sept.' }
                if (yearname.toString().substring(4, 6) == '10') { monthName = 'Oct.' }
                if (yearname.toString().substring(4, 6) == '11') { monthName = 'Nov.' }
                if (yearname.toString().substring(4, 6) == '12') { monthName = 'Dec.' }
            }
            return monthName + ' ' + year
        },

        selectTime(value) {
            if (value > 0) {
                this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
            } else {
                this.api.defaults.baseURL = this.axios.defaults.baseURL
            }
        },

        showUseStatus(h, params) {
            return this.UseStatus(h, params.row.UseStatus)
        },

        UseStatus(h, UseStatus,) {
            switch (UseStatus) {
                case 'NeedCheck':
                    return h('Tag', { props: { color: 'gold' }, slot: 'extra' }, 'NeedCheck')
                case 'Inactive':
                    return h('Tag', { props: { color: '#808080' }, slot: 'extra' }, 'Inactive')
                case '1stTimeUse':
                    return h('Tag', { props: { color: 'red' }, slot: 'extra' }, '1stTimeUse')
                case 'Active':
                    return h('Tag', { props: { color: 'green' }, slot: 'extra' }, 'Active')
                default:
                    return h('Tag', { props: { color: 'default' }, slot: 'extra' }, UseStatus)
            }
        },


        showCustomerStatus(h, params) {
            return this.CustomerStatus(h, params.row.CustomerCredits.CustomerStatus)
        },

        CustomerStatus(h, CustomerStatus,) {
            switch (CustomerStatus) {
                case 'newCustomer':
                    return h('Tag', { props: { color: 'success' }, slot: 'extra' }, 'New Customer')
                case 'oldCustomer':
                    return h('Tag', { props: { color: '#16B7FF' }, slot: 'extra' }, 'Old Customer')
                default:
                    return h('Tag', { props: { color: 'default' }, slot: 'extra' }, CustomerStatus)
            }
        },

        setParams(query) {
            if (Object.keys(query).length != 0) {
                // console.log(query)
                this.fromdate = query.from;
                this.todate = query.to;
                this.searchData.datetime = [query.from, query.to];
                this.searchData.productId = query.productId || "";
                this.searchData.machineId = query.machineId || "";
            }
        },

        isAdmin(role) {
            return webServices.isAdmin(role)
        },

        isDealer(role) {
            return webServices.isDealer(role)
        },

        changePage(PageNum) {
            // window.scrollTo(0, 0)
            this.getMonthly(PageNum)
        },

        renderDetail(h, params) {
            return h('Button', {
                props: {
                    type: 'primary',
                    size: 'small',
                    icon: 'ios-expand'
                },
                style: {
                    marginRight: '5px'
                },
                on: {
                    click: () => {
                        this.detail(params.row)
                    }
                }
            })
        },

        getMachine() {
            this.$Progress.start();
            this.axios
                .get(`/machines/list`)
                .then((res) => {
                    this.$Progress.finish();
                    this.machineData = res.data;
                    if (this.machineData.length == 1) {
                        this.selectedMachine = this.machineData;
                    }
                })
                .catch((err) => {
                    this.$Progress.fail();
                    this.$toast.error({
                        title: "ERROR",
                        message: {
                            error: err.response.data,
                            code: err.response.status,
                            text: err.response.statusText,
                        },
                    });
                    console.log("error @machine");
                    console.log(err);
                    if (err.response.status === 401) {
                        webServices.tokenExpire();
                    }
                });
        },

        showAlert_ChooseThanOneBranch() {
            if (window.localStorage.getItem("language") == 'th') {
                var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
                var text = "เลือกมากกว่า 1 สาขา \n " + "ไม่สามารถเลือกเดือนได้มากกว่า 2 เดือน\n"
                var confirmButton = "ปิด"
            } else {
                var title = "Please select a new time again"
                var text = "Choose than 1 branch, \n " + "Cannot select more than 2 months.\n"
                var confirmButton = "Close"
            }

            this.$swal({
                title: title,
                html: '<pre>' + text + '</pre>',
                // text: text ,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButton
            })
        },

        showAlert_ChooseOneBranch() {
            if (window.localStorage.getItem('language') == 'th') {
                var title = 'โปรดเลือกช่วงเวลาใหม่อีกครั้ง'
                var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 6 เดือน'
                var confirmButton = 'ปิด'
            } else {
                var title = 'Please select a new time again'
                var text = 'Cannot select more than 6 months.'
                var confirmButton = 'Close'
            }

            this.$swal({
                title: title,
                text: text,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButton
            })
        },

        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },

        searchFn(opt = { excel: false }) {
            if (this.searchData.datetime !== '') {
                this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
                // this.todate = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')
                this.todate = moment(this.searchData.datetime[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss");

            } else {
                this.fromdate = ''
                this.todate = ''
            }

            if (this.searchData.type != 'washing') {
                this.transactionType = 'ALL'
            }

            var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
            var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

            this.searchData.userName = localStorageUser.userName
            this.searchData.userRole = localStorageUser.role
            this.searchData.userType = localStorageUser.type
            this.searchData.userConfiglanguage = localStorageUser.config.language
            this.searchData.datetimeRequest = datetimeRequest

            var dateStart = new Date(this.searchData.datetime[0])
            var dateEnd = new Date(this.searchData.datetime[1])

            // case เลือก 1 สาขา จะเลือกช่วงเวลาได้มากสุด 1 ปี
            if (this.searchData.machineId.length == 1 || window.localStorage.getItem("roleID") == 'dealer') {
                console.log("เลือก 1 สาขา หรือ dealer")
                if (this.monthDiff(dateStart, dateEnd) < 6) {
                    if (!opt.excel) {
                        this.getMonthly(1)
                    } else {
                        this.exportExcel()
                    }

                } else {
                    console.log("เลือกเดือนได้สูงสุด 12 เดือน")
                    this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
                }
                // case เลือกมากกว่า 1 สาขา จะเลือกช่วงเวลาได้มากสุด 2 เดือน
            } else {
                console.log("เลือกมากกว่า 1 สาขา")
                if (this.monthDiff(dateStart, dateEnd) < 2) {
                    this.getMonthly(1)
                    if (!opt.excel) {
                        this.getMonthly(1)
                    } else {
                        this.exportExcel()
                    }

                } else {
                    console.log("เลือกเดือนได้สูงสุด 2 เดือน")
                    this.showAlert_ChooseThanOneBranch() // เลือกเดือนได้สูงสุด 2 เดือน
                }
            }
        },

        async getMonthly(page = 1) {
            this.totalRows = 0
            this.series_pie = []
            this.chartOptions_pie.labels = []
            this.chartOptions.xaxis.categories = []

            this.series[0].data = []
            this.series[1].data = []
            this.series[2].data = []
            this.series[3].data = []
            this.series[4].data = []
            // this.rowData = []
            this.overviewData = []
            // this.yearMonthIntersection = []
            // this.summaryData_yearMonth = []

            this.$Progress.start()
            this.currentPage = page

            const rows = this.itemperPage
            const params = {
                ...this.searchData,
                page: page,
                rows: rows,
                // machine: this.selectedMachine,
                // from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
                // to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
                from: this.searchData.from = this.searchData.caseSelect != 'newcustomer'
                    ? moment(this.searchData.datetime[0]).startOf("month").format("YYYY-MM-DD HH:mm:ss")
                    : moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),

                to: this.searchData.to = this.searchData.caseSelect != 'newcustomer'
                    ? moment(this.searchData.datetime[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss")
                    : moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),

                // to: (this.searchData.to = moment(this.searchData.datetime[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss")),
                // type: "washing",
                // notThinkStatus: [
                //     "CANCEL_BY_MC_FAIL",
                //     "CANCEL_PAYMENT",
                //     "CANCEL_TIMEOUT",
                // ],
            };


            // delete params.id
            // delete params.datetime

            await this.axios
                .get(`/analyze/monthly`, {
                    params,
                })
                .then((res) => {
                    //   this.SaleByType = res.data.data;
                    this.rowData = res.data
                    console.log('this.rowData', this.rowData)
                    console.log('data_byYeatMonth', res.data.data_byYeatMonth)

                    var transactionAll = res.data.transactionAll
                    var data_byYeatMonth = res.data.price_byYeatMonth
                    var yearMonthAll = []

                    this.yearMonthIntersection = res.data.yearMonthIntersection
                    this.summaryData_yearMonth = res.data.summaryData_yearMonth

                    if (res.data) {
                        this.overviewData = res.data.data_byYeatMonth

                        this.totalRows = res.data.count.trans
                        this.summary.all = res.data.count.total

                        this.summary.avg_lastDayOfUse = res.data.count.avg_lastDayOfUse
                        this.summary.avg_monthUsed = res.data.count.avg_monthUsed

                        this.summary.summary_customerAll = res.data.summary.summary_customerAll
                        this.summary.summary_active = res.data.summary.summary_active
                        this.summary.summary_inactive = res.data.summary.summary_inactive
                        this.summary.summary_1stTimeUse = res.data.summary.summary_1stTimeUse
                        this.summary.summary_needCheck = res.data.summary.summary_needCheck
                        this.summary.summary_Revenue = res.data.summary.summary_Revenue
                        this.summary.summary_1stUseIn = res.data.summary.summary_1stUseIn

                        this.max = ((res.data.summary_customerAll - (res.data.summary_customerAll % 100)) + 100)

                        for (var i = 0; i < this.rowData.rows.length; i++) {
                            if (page > 1) {
                                this.rowData.rows[i]['index'] = (50 * (page - 1)) + (i + 1)
                            } else {
                                this.rowData.rows[i]['index'] = i + 1
                            }
                        }
                        // this.chartOptions.xaxis.categories = this.yearMonthIntersection

                        for (var a = 0; a < this.overviewData.length; a++) {
                            this.series_pie.push(this.overviewData[a].total)
                            this.series[0].data.push(this.overviewData[a].customer_all)
                        }


                        for (var b = 0; b < this.summaryData_yearMonth.length; b++) {
                            var customerActive = this.summaryData_yearMonth[b].customerActive
                            var customerInactive = this.summaryData_yearMonth[b].customerInactive
                            var customer1stTimeUse = this.summaryData_yearMonth[b].customer1stTimeUse
                            var customerNeedCheck = this.summaryData_yearMonth[b].customerNeedCheck

                            this.series[1].data.push(customerActive)
                            this.series[2].data.push(customerInactive)
                            this.series[3].data.push(customer1stTimeUse)
                            this.series[4].data.push(customerNeedCheck)
                        }

                        for (var c = 0; c < this.yearMonthIntersection.length; c++) {
                            this.chartOptions_pie.labels.push(this.getMonthName(this.yearMonthIntersection[c]))
                            this.chartOptions.xaxis.categories.push(this.getMonthName(this.yearMonthIntersection[c]))
                        }


                    }
                    this.$Progress.finish()
                })
                .catch((err) => {
                    this.$Progress.fail();
                    console.log(err);
                    this.$toast.error({
                        title: "ERROR",
                        message: err,
                    });
                    if (err.response.status === 401) {
                        webServices.tokenExpire();
                    }
                });
        },

        exportExcel(page = 1) {
            this.$Progress.start()



            // for (let i = 0; i < this.machineData.length; i++) {
            //   if( this.searchData.machineId === this.machineData[i].machineId){
            //     const machineName =  this.machineData[i].name
            //     this.searchData.name = machineName.substr(9).replaceAll('\\r\\n', '')
            //   }
            // }

            if (this.searchData.machineId.length > 0) {
                for (let i = 0; i < this.machineData.length; i++) {
                    if (this.searchData.id === this.machineData[i].machineId) {
                        console.log('this.searchData', this.searchData)
                        const machineName = this.machineData[i].name
                        // this.searchData.machineId = this.machineData[i].machineId
                        this.searchData.name = machineName.substr(9).replaceAll('\\r\\n', '')
                    }
                }

                this.searchData.branch = this.searchData.machineId
            } else {
                this.searchData.branch = '...'
                this.searchData.name = '...'
            }

            console.log('this.searchData', this.searchData)
            // const dateStart = new Date(this.fromdate)
            // const dateEnd = new Date(this.todate)


            this.currentPage = page

            const rows = this.itemperPage

            const params = {
                ...this.searchData,
                page: page,
                rows: rows,
                machine: this.selectedMachine,
                // from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
                // to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
                from: (this.searchData.from = moment(this.searchData.datetime[0]).startOf("month").format("YYYY-MM-DD HH:mm:ss")),
                to: (this.searchData.to = moment(this.searchData.datetime[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss")),
                // startDate: `${start_fulldate}`,
                // endDate: `${end_fulldate}`,
                type: "washing",
                notThinkStatus: [
                    "CANCEL_BY_MC_FAIL",
                    "CANCEL_PAYMENT",
                    "CANCEL_TIMEOUT",
                ],
            };

            // delete params.id
            // delete params.datetime


            this.axios({
                url: `/analyze/monthly/download`, // File URL Goes Here
                method: 'GET',
                params,
                responseType: 'blob'
            }).then(response => {
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                let fileName

                if (this.searchData.caseSelect == 'overview') {
                    fileName = 'Monthly_Overview'
                }
                if (this.searchData.caseSelect == 'individual') {
                    fileName = 'Monthly_Individual'
                }
                if (this.searchData.caseSelect == 'newcustomer') {
                    fileName = 'Monthly_New_Custoemr'
                }

                FileSaver.saveAs(response.data, `Report_${fileName}_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)


                this.$Progress.finish()
            })
        },


        detail(data) {
            console.log('log push page order', data)
            const params = {
                // ...this.searchData,
                ...data,
                // from: moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
                // to: moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
                from: (this.searchData.from = moment(this.searchData.datetime[0]).startOf("month").format("YYYY-MM-DD HH:mm:ss")),
                to: (this.searchData.to = moment(this.searchData.datetime[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss")),
                select_machine: 1,
                byPage: 'analyzeMonthly'

                // productId: productId,
                // from: moment(this.filters.date[0]).format('YYYY-MM-DD HH:mm:ss'),
                // to: moment(this.filters.date[1]).format('YYYY-MM-DD HH:mm:ss')
            }

            // if (this.filters.machineId) {
            //   params.machineId = this.filters.machineId
            // }
            this.$router.push({ path: '/orders', query: params })
        },

        onSelectMachine(items) {
            // console.log(items)
            this.selectedMachine = items
            this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
            this.searchData.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', ''))
        },

        onSelectStatusUse(items) {
            // console.log('onSelectType', items)
            this.selectedStatusUse = items;
            this.searchData.statusUse = this.selectedStatusUse.map(
                (data) => data.value
            );
        },

        onSelectCustomerStatus(items) {
            // console.log('onSelectType', items)
            this.selectedCustomerStatus = items;
            this.searchData.customerStatus = this.selectedCustomerStatus.map(
                (data) => data.value
            );
        },

        changeTab(params) {

            this.searchData.caseSelect = params
            console.log('changeTab', this.searchData.caseSelect)

            this.searchData.reqRank = ''
            delete this.searchData.refNumber

            this.rowData = {}
            this.totalRows = 0
            this.totalSubRows = 0
            this.summary = {
                avg_lastDayOfUse: 0,
                avg_monthUsed: 0,
                summary_customerAll: 0,
                summary_active: 0,
                summary_inactive: 0,
                summary_1stTimeUse: 0,
                summary_needCheck: 0,
                summary_Revenue: 0,
                summary_1stUseIn: 0,
            }

            if (this.searchData.caseSelect == 'newcustomer') {
                this.searchData.datetime = [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
            }

            if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
                this.searchFn()
            }
        }


    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>